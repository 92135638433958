import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://2b98c5a543ae93f976732c76f7f0cfe7@o244825.ingest.us.sentry.io/4507616626999296",
  debug: false,
  environment: "development",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});